var swiper = new Swiper(".brands-swiper", {
  slidesPerView: 2,
  spaceBetween: 30,
  autoplay: {},
  breakpoints: {
    500: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
    800: {
      slidesPerView: 5,
      spaceBetween: 40,
    },
    1000: {
      slidesPerView: 6,
      spaceBetween: 45,
    },
    1300: {
      slidesPerView: 7,
      spaceBetween: 45,
    },
    1500: {
      slidesPerView: 8,
      spaceBetween: 45,
    },
    1900: {
      slidesPerView: 10,
      spaceBetween: 50,
    },
  },
  // autoplay: {
  //   reverseDirection: true,
  // },
});

var swiper2 = new Swiper(".clients-swiper", {
  slidesPerView: 1,
  spaceBetween: 30,
  // autoplay: {},
  breakpoints: {
    400: {
      slidesPerView: 1,
      spaceBetween: 40,
    },
    800: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 45,
    },
    1600: {
      slidesPerView: 4,
      spaceBetween: 45,
    },

    1900: {
      slidesPerView: 6,
      spaceBetween: 50,
    },
  },
  autoplay: {
    reverseDirection: true,
  },
});
